import IconInfo from '../molecules/info-icon';
import Header from '../molecules/info-header';
import { useIsMobileScreen } from '@/app/hooks/useIsMobileScreen';
import { cn } from '@/app/utils/cn';
import VersionInfo from '@/app/components/molecules/version-info';

const LeftSideInfo = () => {
    const isMobileScreen = useIsMobileScreen();

    return (
        <div
            className={cn(
                'flex flex-1 flex-col relative bg-dark-blue-bg items-center justify-center px-[37px] py-[20px]',
                'lg:pr-[144px] lg:pl-[115px]'
            )}
        >
            {!isMobileScreen && <Header className="mb-[39px]" />}
            <div className="flex flex-col gap-y-[18px]">
                <IconInfo
                    iconName="price-tag"
                    title="Configuración sin complicaciones"
                    text="Se conecta automáticamente con el catálogo de tu tienda."
                />
                <IconInfo
                    iconName="vision-ai"
                    title="Visión IA contextual"
                    text="Analiza imágenes para enriquecer y completar la información de tus productos."
                />
                <IconInfo
                    iconName="chart-timeline-variant-shimmer"
                    title="Completa tu Look"
                    text="Aumenta ventas recomendando productos que complementan lo que tus clientes estaban buscando."
                />
            </div>
            <VersionInfo className={cn('text-white relative px-0 ', 'sm:absolute sm:p-8')} />
        </div>
    );
};

export default LeftSideInfo;
