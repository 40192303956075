import Image from 'next/image';
import { cn } from '@/app/utils/cn';

import useUser from '@/app/contexts/userContext';
import LimitationBanner from '../molecules/limitation-banner';

interface HeaderProps {
    onSidebarToggle: () => void;
}

const Header = ({ onSidebarToggle }: HeaderProps) => {
    const defaultLogo = '/images/delfi-logo.png';

    const { client, clientPlan } = useUser();

    return (
        <header
            className={cn(
                `${clientPlan.isFree() ? 'h-30' : 'h-20'}`,
                'bg-white flex flex-col  fixed inset-x-0 z-20 shadow-10'
            )}
        >
            {clientPlan.isFree() && (
                <LimitationBanner text="Accede a todo el potencial de tu Asesora de Moda con el Plan Full IA" />
            )}
            <section className="flex flex-row w-full items-center">
                <button
                    className="text-black mx-9 p-1 rounded-full hover:bg-slate-100 active:bg-slate-200"
                    onClick={onSidebarToggle}
                >
                    <Image height={24} width={24} src="/icons/menu.svg" alt="menu" />
                </button>

                <div className="w-40 h-20 relative">
                    <Image
                        className="py-2"
                        src={client?.logoUrl || defaultLogo}
                        alt="client logo"
                        fill
                        style={{ objectFit: 'scale-down' }}
                    />
                </div>
            </section>
        </header>
    );
};

export default Header;
